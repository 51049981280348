import { Box, Button, Grid, Tab, Tabs, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/header/Header';
import DashboardServices from '../DashboardServices';
import LoadingData from '../../../components/LoadingData'
import './styles.css'
import CardEnsino from './Card/CardEnsino';
import CardInfo from './PercentualAcertoErro/CardInfo';
import PercentualAcertoErro from './PercentualAcertoErro/PercentualAcertoErro';
import AutoCompleteCustom from '../../../components/AutoCompleteCustom';
import { calcularPorcentagem } from '../../../utils/Utils';

const Dashboard = () => {
    const dispatch = useDispatch()

    const [tab, setTab] = useState(0)
    const [habilidade, setHabilidade] = useState(0)
    const [listaHabilidades, setListaHabilidades] = useState(null)
    const [page, setPage] = useState(1)

    const {
        ensinos,
        series,
        disciplinas,
        habilidades,
        habilidadesGeral,
        totais,
        acertosPorHabilidade,
    } = useSelector(st => st.dashboard)

    useEffect(() => {
        DashboardServices.getEnsinos(dispatch, { limit: 0 });
        DashboardServices.getDisciplinas(dispatch, { limit: 0 });
        DashboardServices.getSeries(dispatch, { limit: 0 });
        DashboardServices.getHabilidadesGeral(dispatch, { limit: 0, possui_itens: 1 });
        DashboardServices.getTotais(dispatch, { limit: 0 });
        loadHabilidades(1)
    }, [])

    const loadHabilidades = (page, habilidade_id = null) => {
        let params = { limit: 0/* , page */ };
        if (habilidade_id) {
            params.habilidade_id = habilidade_id;
        }
        DashboardServices.getAcertosPorHabilidade(dispatch, params)
    }

    const CardQuantitativo = ({ title, subtitle }) => {
        return (
            <div className='dashboard_div_card'>
                <div className='title'>{title}</div>
                <div className='subtitle'>{subtitle}</div>
            </div>
        )
    }

    const alteraHabilidade = (value) => {
        setHabilidade(value)
        loadHabilidades(1, value?.id)
    }
    
    useEffect(() => {
        if (acertosPorHabilidade && acertosPorHabilidade.dados && habilidadesGeral && habilidadesGeral.dados) {
            let dados = acertosPorHabilidade.dados
            let aux = {}
            dados.forEach(el => {
                if (!aux[el.habilidade_id]) {
                    aux[el.habilidade_id] = el
                }
            })
            let habilidadesAux = []
            habilidadesGeral.dados.forEach(el => {
                if (aux[el.id]) {
                    habilidadesAux.push({ ...aux[el.id], ...el })
                }
            })
            setListaHabilidades(habilidadesAux)
        }
    }, [acertosPorHabilidade, habilidadesGeral])

    const renderLista = () => {
        if (tab === 0) {
            return ensinos &&
                ensinos.dados &&
                ensinos.dados.map((e, k) =>
                    <CardEnsino
                        key={k}
                        ensino={e}
                    />
                )
        }

        if (tab === 1) {
            return (
                <CardInfo
                    style={{ marginTop: 10 }}
                    rightTitle={() => (
                        <AutoCompleteCustom
                            style={{ width: 300 }}
                            label={"Filtre por habilidade"}
                            placeholder={"Filtre por habilidade"}
                            onChange={(value) => alteraHabilidade(value)}
                            options={habilidadesGeral && habilidadesGeral?.dados ? habilidadesGeral.dados : []}
                            value={habilidade}
                            empty={'Nenhuma habilidade encontrada'}
                        />
                    )}
                    title={<div style={{ marginTop: 10 }} >HABILIDADES (acerto x erros)</div>}>
                    <Grid container spacing={2} style={{ marginTop: 0 }}>
                        {habilidadesGeral && habilidadesGeral.dados && acertosPorHabilidade && acertosPorHabilidade.dados &&
                            listaHabilidades.map((h, k) => {
                                let { acerto, erro } = calcularPorcentagem(parseInt(h.acertos), parseInt(h?.erros))
                                return (
                                    <Grid key={k} item xs={12} md={3} sm={3}>
                                        <PercentualAcertoErro
                                            title={h.codigo}
                                            acerto={acerto}
                                            erro={erro} />
                                    </Grid>
                                )
                            })}
                        {listaHabilidades && listaHabilidades.length === 0 &&
                            <Grid item xs={12} style={{ fontSize: 12 }}>
                                <Alert severity="info" sx={{ marginTop: 1 }}>Nenhuma habilidade encontrada!</Alert>
                            </Grid>
                        }
                        {acertosPorHabilidade && acertosPorHabilidade.dados && acertosPorHabilidade.dados.length >= 12 &&
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button
                                    style={{ marginTop: 5, marginBottom: 20 }}
                                    onClick={() => {
                                        loadHabilidades(page + 1);
                                        setPage(page + 1);
                                    }}
                                    variant='contained'
                                    size='small'
                                    color='info'>
                                    Ver mais
                                </Button>
                            </Grid>
                        }
                        {acertosPorHabilidade && <LoadingData obj={acertosPorHabilidade} msg={"Buscando acertos por habilidade..."} />}
                    </Grid>
                </CardInfo>
            )
        }
    }

    return (
        <>
            <Header title={"Dashboard"} />
            <Grid container spacing={2} style={{ marginTop: 0, marginBottom: 20 }} >
                <Grid item xs={12}>
                    {ensinos && <LoadingData obj={ensinos} msg={"Buscando ensinos..."} />}
                    {series && <LoadingData obj={series} msg={"Buscando series..."} />}
                    {disciplinas && <LoadingData obj={disciplinas} msg={"Buscando disciplinas..."} />}
                    {habilidades && <LoadingData obj={habilidades} msg={"Buscando habilidades..."} />}
                    {totais && <LoadingData obj={totais} msg={"Buscando totais..."} />}
                </Grid>
                <Grid item xs={12} md={3} sm={3}>
                    <CardQuantitativo
                        title={"Avaliações"}
                        subtitle={totais?.dados?.num_avaliacoes}
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={3}>
                    <CardQuantitativo
                        title={"Alunos"}
                        subtitle={totais?.dados?.num_alunos}
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={3}>
                    <CardQuantitativo
                        title={"Meus itens"}
                        subtitle={totais?.dados?.num_usuario_itens}
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={3}>
                    <CardQuantitativo
                        title={"Banco de itens"}
                        subtitle={totais?.dados?.num_banco_itens}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={(e, value) => setTab(value)} aria-label="basic tabs example">
                            <Tab label="Listar por ensinos" />
                            <Tab label="Listar por Habilidades" />
                        </Tabs>
                    </Box>
                </Grid>
                {renderLista()}
            </Grid>
        </>

    )
}

export default Dashboard;