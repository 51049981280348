
export const getDateByDateStr = (datestr) => {
    let datearr = []

    if (datestr.includes('-')) {
        datearr = datestr.split('-').reverse()
    }

    if (datestr.includes('/')) {
        datearr = datestr.split('/')
    }
    return new Date(datearr[2], parseInt(datearr[1]) - 1, datearr[0])
}


export const completaZero = (n) => {
    if (n < 10) {
        return '0' + n;
    }
    return n;
}

export const formataData = (date) => {
    try {
        return completaZero(date.getDate()) + "/" + completaZero(date.getMonth() + 1) + "/" + date.getFullYear();
    } catch (e) {
        console.log(e)
        return '';
    }
}

export const formataData2 = (date) => {
    if(!date){
        return ''
    }
    // Divide a string da data nos componentes dia, mês e ano
    var partes = date.split('/');
    
    // Obtém os componentes da data
    var dia = partes[0];
    var mes = partes[1];
    var ano = partes[2];
  
    // Formata a data para o formato YYYY-MM-DD
    var dataFormatada = ano + '-' + mes + '-' + dia;
  
    return dataFormatada;
  }
  

export const getDataFormatadaDaDataCompleta = (date) => {
    return date.split(' ')[0].split('-').reverse().join('/')
}