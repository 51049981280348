import { Avatar, Button, Card, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Masked from 'vanilla-masker';
import Upload from "../../../components/upload";
import { KEY_USER_BNCC_APP } from "../../../config/GlobalKeys";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { setUser } from "../../../reducers/global";
import { formataData, formataData2 } from "../../../utils/formatDate";
import PerfilService from "../PerfilService";
import toastr from 'toastr';

const Perfil = () => {
    const dispatch = useDispatch()
    const { set } = useLocalStorage()

    const { user } = useSelector(state => state.global);

    const [cpf, setCpf] = useState(user.relacionado.cpf);
    const [telefone, setTelefone] = useState(user.relacionado.telefone);
    const [dataNascimento, setDataNascimento] = useState(user.relacionado.data_nascimento ? formataData(new Date(user.relacionado.data_nascimento + " 00:00:00")) : '');
    const [img, setImg] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleEditPerfil = (dados) => {
        if(dados.data_nascimento.length){
            if(new Date(dados.data_nascimento) == "Invalid Date" || dados.data_nascimento.includes('undefined')){
                toastr.warning("Dados inválidos: Data de nascimento inválida!");
                return;
            }
            
            const dataAtual = new Date().setHours(0, 0, 0, 0)
            const dataDigitada = new Date(dados.data_nascimento + 'T00:00:00')

            if(dataDigitada >= dataAtual){
                toastr.warning("Dados inválidos: A data de nascimento deve estar no passado!");
                return;
            }
        }     

        setLoading(true)
        
        PerfilService.editarPerfil(dispatch, { ...dados, id: user.id }, (dados, success) => {
            if (success) {
                let userAux = { ...user, relacionado: dados }
                set(KEY_USER_BNCC_APP, userAux)
                dispatch(setUser(userAux))
                setLoading(false)
            } else {
                setLoading(false)

            }
        })
    }



    const handleCpfChange = (event) => {
        setCpf(Masked.toPattern(event.target.value, "999.999.999-99"));
    };

    const handleTelefoneChange = (event) => {
        setTelefone(Masked.toPattern(event.target.value, "(99) 99999-9999"));
    };

    const handleDataNascimentoChange = (event) => {
        setDataNascimento(Masked.toPattern(event.target.value, "99/99/9999"));
    };

    const handleUpload = files => {
        let uploadFiles = files.map((file, idx) => {
            let preview = URL.createObjectURL(file);

            let meta_arquivo = {
                datetime: formataData(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                type: file.type,
            }

            if (file.type.match('image')) {
                let _image = new Image()
                _image.onload = function () {
                    meta_arquivo.width = this.width
                    meta_arquivo.height = this.height
                }
                _image.src = preview
            }

            return {
                file,
                meta_arquivo,
                preview: preview,
                progress: 0,
                uploaded: false,
                url: null,
                isLocal: true,
            }

        })

        uploadFiles = uploadFiles.filter(u => u.error == null)
        setImg(uploadFiles.length >0 ?  uploadFiles[0] : null)
    }

    return (
        <Grid container spacing={3} mb={3}>
            <Grid item xs={12} sm={6} md={4} >
                <Card sx={{ padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                    <Upload
                        multiple={false}
                        onlyImage={true}
                        onUpload={handleUpload}
                    >
                        <Avatar src={img? img.preview : user.relacionado.img} sx={{ width: '20vh', height: '20vh' }} />
                    </Upload>
                    <Typography mt={1}>
                        {user.nome}
                    </Typography>
                    <Typography mt={2}>
                        {user.email}
                    </Typography>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
                <Card sx={{ padding: 2, height: '100%' }}>
                    <Typography variant="h5">
                        Editar dados
                    </Typography>
                    <Grid container spacing={2} mt={1}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                size="medium"
                                label="CPF"
                                variant="outlined"
                                value={cpf || ''}
                                onChange={handleCpfChange}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                size="medium"
                                label="Telefone"
                                variant="outlined"
                                value={telefone || ''}
                                onChange={handleTelefoneChange}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                size="medium"
                                label="Data de nascimento (DD/MM/AAAA)"
                                variant="outlined"
                                value={dataNascimento || ''}
                                onChange={handleDataNascimentoChange}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'flex-end'} alignItems={'flex-end'} spacing={2} mt={1}>
                        <Grid item md={2}>
                            <Button
                                onClick={(e) => handleEditPerfil({ cpf, telefone, data_nascimento: formataData2(dataNascimento), img: img && img.file? img.file: null })}
                                type='submit'
                                disabled={loading}
                                style={{ width: '100%' }}
                                variant='contained'>
                                {loading ? 'SALVANDO...' : 'SALVAR'}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Perfil; 