import React, { useState, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { DropContainer, UploadMessage } from './styles';

const Upload = ({ multiple, onlyImage, onlyVideo, onlyAudio, imgAndPdf, onUpload, type, message, children }) => {
  const [error, setError] = useState(null);

  // Função chamada quando arquivos são soltos
  const handleDrop = useCallback((acceptedFiles, fileRejections) => {
    setError(null);

    // Se houverem arquivos rejeitados, exibe a mensagem de erro
    if (fileRejections.length > 0) {
      setError('Arquivo Não Suportado!');
    }

    // Se houver arquivos aceitos, chama a função onUpload
    if (acceptedFiles.length > 0 && onUpload) {
      onUpload(acceptedFiles);
    }
  }, [onUpload]);

  // Renderiza a mensagem de arraste e soltar
  const renderDragMessage = (isDragActive) => {
    if (!isDragActive) {
      return (
        <UploadMessage type={type || 'default'}>
          {message ? message : 'Clique ou Arraste para cá!'}
        </UploadMessage>
      );
    }

    return <UploadMessage type="success">Solte o arquivo aqui!</UploadMessage>;
  };

  // Define os tipos de arquivo permitidos
  const fileTypes = () => {
    if (imgAndPdf) {
      return ['image/*', 'application/pdf'];
    }
    if (onlyImage) {
      return ['image/*'];
    }
    if (onlyAudio) {
      return ['audio/*'];
    }
    if (onlyVideo) {
      return ['video/*'];
    }
    return ['application/pdf'];
  };

  return (
    <Dropzone
      accept={fileTypes()}
      multiple={multiple}
      onDrop={handleDrop} // Modificado para validar na solta
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <DropContainer {...getRootProps()} isDragActive={isDragActive}>
          {children}
          <input {...getInputProps()} />

          {/* Exibe a mensagem de erro se houver */}
          {error ? (
            <UploadMessage type="error">{error}</UploadMessage>
          ) : (
            renderDragMessage(isDragActive)
          )}
        </DropContainer>
      )}
    </Dropzone>
  );
};

export default Upload;


